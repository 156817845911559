export const navLinksData = [
    
    {
        _id: 101,
        title: "Home",
        link: "home",
    },
    {
        _id: 102,
        title: "Features",
        link: "features",
    },
    {
        _id: 103,
        title: "Projects",
        link: "projects",
    },
    {
        _id: 104,
        title: "Resume",
        link: "resume",
    },
    {
        _id: 105,
        title: "Contact",
        link: "contact",
    },
]